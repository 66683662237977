import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllSkippedCall, getAllQueueProcess, getCounterPoli } from "../../../actions";
import "./index.css";
import Topbar from "../../../components/Topbar";
import { io } from "socket.io-client";
import { socketBase, socketPath } from "../../../urlConfig";
import { maxLimit, queueProcessConstants } from "../../../actions/constants";
import { isAntrol, isMultiScreen } from "../../../helpers/kioskConfigs";
import Select from "../../../components/Select";

const socket = io(socketBase, {path: socketPath}).connect();


export default function QueueAdmissionDisplay() {
  const { machineId } = useParams();

  const searchParams = new URLSearchParams(window.location.search);
  
  const { tvCounter, tvDisplayMissed } = maxLimit

   // Room State
   const [room, setRoom] = useState(null);

  // Queues State
  let { queueProcessSkipped, allQueueProcess } = useSelector((state) => state.queueProcess);

  // Category State for isMultiScreen Config
  // if the url has query ?category=BPJS or Non-BPJS will set the initial state of category
  const [category, setCategory] = useState(isAntrol && isMultiScreen ? {category: 'BPJS', poliId:'', value:'BPJS'} : {category: '', poliId:'', value: "Semua poli"});
  let payloadOptions = isMultiScreen ? [{ title: 'BPJS', value:JSON.stringify({category: 'BPJS', poliId:'', value:'BPJS'}) }, { title: 'Non-BPJS', value:JSON.stringify({category: 'Non-BPJS', poliId:'', value: 'Non-BPJS'}) }] : [{ title: 'Semua poli', value: JSON.stringify({category: '', poliId:'', value: 'Semua poli'}) }]
  const [categoryOptions, setCategoryOptions] = useState([])
  const categoryRef = useRef(category);
  // Text to Speech
  const synth = window?.speechSynthesis;
  const voice = window?.speechSynthesis?.getVoices();
  const indonesianVoice = voice.find((voice) => voice.lang.includes('id-ID'));
  const utterance = new SpeechSynthesisUtterance();
  // set to indonesian voice
  utterance.voice = indonesianVoice;
  utterance.pitch = 1;
  utterance.rate = 1;
  utterance.lang = 'id-ID'

  const dispatch = useDispatch();

  useEffect(() => {
    setRoom(machineId);
    dispatch(getCounterPoli()).then((data)=>{
      data.forEach((dataInside)=>{
        payloadOptions.push({title: dataInside.name, value:JSON.stringify({category: '', poliId:dataInside.poliId, value: dataInside.name})})
      })
      setCategoryOptions(payloadOptions)
    })
    dispatch(getAllQueueProcess(machineId, category.category)).catch((err) => console.log(err));
    dispatch(getAllSkippedCall(machineId, tvDisplayMissed, category.category, category.poliId)).catch((err) => console.log(err));
  }, [machineId, tvDisplayMissed]);

  useEffect(() => {
    socket.emit("join_room", room);
  }, [room]);

useEffect(() => {
  socket.on("updateReduxAdmissionDisplay", (newData) => {
    // if config ismultiscreen is on
    //updateCategory is needed for both category screens to update with socket
    // updateCategory is from update config layar rajal
    if(isAntrol && isMultiScreen && (categoryRef.current === newData?.category || newData?.updateCategory || category.poliId === newData?.category)){
      let updateCategory;
    if (newData?.updateCategory) updateCategory = true
    if(newData.skipped){
      if(updateCategory) {
        dispatch(getAllSkippedCall(machineId, tvDisplayMissed, categoryRef.current, category.poliId)).catch((err) => console.log(err));
      } else {
      dispatch({
        type: queueProcessConstants.GET_ALL_SKIPPED_QUEUE_PROCESS_SUCCESS,
        payload: newData.skipped,
      });
    }
    }
    
    if(updateCategory){
      dispatch(getAllQueueProcess(machineId, categoryRef.current, category.poliId)).catch((err) => console.log(err));
    } else {
    dispatch({
      type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_SUCCESS,
      payload: newData.process,
    });
    }

    if(newData?.text){
      utterance.text = newData?.text;
      synth.speak(utterance);
    }
    }

    if(!isMultiScreen) { // if config isMultiScreen is off
      if(categoryRef.current === newData?.category || newData?.updateCategory || category.poliId === newData?.category){
        if (newData.skipped) {
          dispatch({
            type: queueProcessConstants.GET_ALL_SKIPPED_QUEUE_PROCESS_SUCCESS,
            payload: newData.skipped,
          });
        }
    
        dispatch({
          type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_SUCCESS,
          payload: newData.process,
        });
        
        if(newData?.text){
          utterance.text = newData?.text;
          synth.speak(utterance);
        }
      }
    }
  });

  // this socket is for re-calling queue number also to retry updating to the current queue number and skipped queue number if no show in tv screen
  socket.on("receive_message_antrol", (data) => {
    // if config ismultiscreen is on , only re-call queue number in the same category of screen
    if (isAntrol && isMultiScreen && (category.category === data?.category || category.poliId === data?.category)) {
      if (data.skipped) {
        dispatch({
          type: queueProcessConstants.GET_ALL_SKIPPED_QUEUE_PROCESS_SUCCESS,
          payload: data.skipped,
        });
      }
      dispatch({
        type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_SUCCESS,
        payload: data.process,
      });
      utterance.text = data.text;
      synth.speak(utterance);
    } else if (!isMultiScreen) {
      if (data.skipped) {
        dispatch({
          type: queueProcessConstants.GET_ALL_SKIPPED_QUEUE_PROCESS_SUCCESS,
          payload: data.skipped,
        });
      }
      dispatch({
        type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_SUCCESS,
        payload: data.process,
      });
      utterance.text = data.text;
      synth.speak(utterance);
    }
  });

  return () => {
    socket.off("updateReduxAdmissionDisplay");
    socket.off("receive_message");
}

}, [socket, category]);

useEffect(() => {
if(category){
  dispatch(getAllQueueProcess(machineId, category.category, category.poliId)).catch((err) => console.log(err));
  dispatch(getAllSkippedCall(machineId, tvDisplayMissed, category.category, category.poliId)).catch((err) => console.log(err));
  categoryRef.current = category.category || category.poliId;
}
}, [category])

  return (
    <div className="queue-bg">
      <div className="queue-container">
        <div>
          <Topbar />
        </div>
        {isAntrol &&
          <div className="topbar-display d-flex justify-content-end">
            <div style={{ width: "400px" }}>
              <Select
                value={JSON.stringify(category)}
                onChange={(e) => setCategory(JSON.parse(e.target.value))}
                data={categoryOptions}
              />
            </div>
          </div>
        }
        <Row className="m-0 justify-content-center w-100">
        <Row className="fw-bold fs-1 px-5">Antrian Rawat Jalan</Row>
          <Row>
            <Row className="fw-bold fs-2 mx-0">
              <div className="text-center">SEDANG DALAM ANTRIAN</div>
            </Row>
            <Row className="mx-0">
              {allQueueProcess && allQueueProcess.length > 0 ? (
                allQueueProcess.slice(0,tvCounter).filter((dataFilter)=>{
                  if(category.poliId != ''){
                    return  category.value == dataFilter.counter
                  } else{
                    return !dataFilter.poliId
                  }
                }).map((data, index) =>{
                  return <Col className="m-3" key={index}>
                  <Row className="text-center rounded-top bg-black text-white fs-2 fw-semibold py-2">
                    <div>{data?.counter}</div>
                  </Row>
                  <Row className="bg-white rounded-bottom fw-bold">
                    <div className="queue-word d-flex align-items-center justify-content-center">
                      {data?.queuePrefixNumber}
                    </div>
                  </Row>
                </Col>
                }
                  
                )
              ) : (
                <Col className="mx-3">
                  <Row className="text-center rounded-top bg-black text-white fs-2 fw-semibold py-2">
                    <div>{category?.value || "Counter"}</div>
                  </Row>
                  <Row className="bg-white rounded-bottom fw-bold">
                    <div className="queue-word d-flex align-items-center justify-content-center">
                      Belum ada antrian
                    </div>
                  </Row>
                </Col>
              )}
            </Row>

            <Row>
            <div className="text-center fw-bold fs-2 mb-4">
                ANTRIAN TERLEWATKAN
              </div>
              <Container>
                <Row>
                  {queueProcessSkipped?.slice(0,tvDisplayMissed).map((data,index) => (
                    <Col key={index} xs={2} >
                      <div className="box-down rounded fw-bold text-center">{data.queuePrefixNumber}</div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Row>
          </Row>
        </Row>
      </div>
    </div>
  );
}
